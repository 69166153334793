import { isUserLoggedIn } from '@/auth/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Главный слайдер',
        breadcrumb: [
          {
            text: 'Главный слайдер',
            active: true,
          },
        ],
      },
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/Services.vue'),
      meta: {
        pageTitle: 'Наши услуги',
        breadcrumb: [
          {
            text: 'Наши услуги',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import('@/views/Reviews.vue'),
      meta: {
        pageTitle: 'Отзывы',
        breadcrumb: [
          {
            text: 'Отзывы',
            active: true,
          },
        ],
      },
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/History.vue'),
      meta: {
        pageTitle: 'Наша история',
        breadcrumb: [
          {
            text: 'Наша история',
            active: true,
          },
        ],
      },
    },
    {
      path: '/statistic',
      name: 'statistic',
      component: () => import('@/views/Statistic.vue'),
      meta: {
        pageTitle: 'Преимущества и статистика',
        breadcrumb: [
          {
            text: 'Преимущества и статистика',
            active: true,
          },
        ],
      },
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('@/views/Team.vue'),
      meta: {
        pageTitle: 'Наша команда',
        breadcrumb: [
          {
            text: 'Наша команда',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/staticcount',
    //   name: 'staticcount',
    //   component: () => import('@/views/StaticCount.vue'),
    //   meta: {
    //     pageTitle: 'Статистика',
    //     breadcrumb: [
    //       {
    //         text: 'Статистика',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/News.vue'),
      meta: {
        pageTitle: 'Новости',
        breadcrumb: [
          {
            text: 'Новости',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import('@/views/Gallery.vue'),
      meta: {
        pageTitle: 'Галерея',
        breadcrumb: [
          {
            text: 'Галерея',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faq-category',
      name: 'faq-category',
      component: () => import('@/views/FAQCategory.vue'),
      meta: {
        pageTitle: 'Частые вопросы',
        breadcrumb: [
          {
            text: 'Частые вопросы',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faqy',
      name: 'faqy',
      component: () => import('@/views/FAQY.vue'),
      meta: {
        pageTitle: 'Часто задаваемые вопросы',
        breadcrumb: [
          {
            text: 'Часто задаваемые вопросы',
            active: true,
          },
        ],
      },
    },
    {
      path: '/procedures-category',
      name: 'procedures-category',
      component: () => import('@/views/ProceduresCategory.vue'),
      meta: {
        pageTitle: 'Категория процедур',
        breadcrumb: [
          {
            text: 'Категория процедур',
            active: true,
          },
        ],
      },
    },
    {
      path: '/procedures',
      name: 'proceduresandroom',
      component: () => import('@/views/ProcedRoom.vue'),
      meta: {
        pageTitle: 'Процедуры',
        breadcrumb: [
          {
            text: 'Процедуры',
            active: true,
          },
        ],
      },
    },
    {
      path: '/room-category',
      name: 'room-category',
      component: () => import('@/views/RoomCategory.vue'),
      meta: {
        pageTitle: 'Категория номеров',
        breadcrumb: [
          {
            text: 'Категория номеров',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rooms',
      name: 'rooms',
      component: () => import('@/views/Rooms.vue'),
      meta: {
        pageTitle: 'Номера',
        breadcrumb: [
          {
            text: 'Номера',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    console.log('jlksdhffjkhjk')
    next({ name: 'login' })
    // next({
    //   path: '/login',
    //   query: { redirect: 'login' },
    // })
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
